/* Prism theme setup */

code[class*='language-'],
pre[class*='language-'] {
  @apply bg-component;
  @apply text-contrast;
  @apply font-mono;
  @apply text-xs;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
}

/* pre {
	@apply p-3 my-9 overflow-auto rounded-md bg-component border border-componentHover;
} */

code {
  @apply rounded border border-componentHover bg-component px-1.5 py-0.5 text-xs text-contrast;
}

pre > code {
  border: none;
}

:not(pre) > code[class*='language-'] {
  padding: 0.1em; /* this is the default */
  border-radius: unset;
  white-space: normal; /* this is standard */
}

.token.comment {
  @apply text-mauve-10;
}

.token.prolog {
  @apply text-mauve-10;
}

.token.cdata {
  @apply text-mauve-10;
}

.token.doctype {
  @apply text-blue-9;
}

.token.punctuation {
  @apply text-olive-11;
}

.token.entity {
  @apply text-contrast;
}

.token.attr-name {
  @apply text-blue-11;
}

.token.class-name {
  @apply text-blue-9;
}

.token.boolean {
  @apply text-yellow-11;
}

.token.constant {
  @apply text-yellow-11;
}

.token.number {
  @apply text-amber-11;
}

.token.atrule {
  @apply text-teal-11;
}

.token.keyword {
  @apply text-teal-11;
}

.token.property {
  @apply text-teal-11;
}

.token.property-access {
  @apply text-teal-11;
}

.token.tag {
  @apply text-blue-9;
}

.token.symbol {
  @apply text-blue-9;
}

.token.deleted {
  @apply text-blue-11;
}

.token.important {
  @apply text-teal-11;
}

.token.selector {
  @apply text-blue-11;
}

.token.string {
  @apply text-crimson-11;
}

.token.char {
  @apply text-crimson-11;
}

.token.builtin {
  @apply text-yellow-11;
}

.token.inserted {
  @apply text-blue-9;
}

.token.regex {
  @apply text-crimson-11;
}

.token.attr-value {
  @apply text-crimson-11;
}

.token.variable {
  @apply text-contrast;
}

.token.operator {
  @apply text-blue-9;
}

.token.function {
  @apply text-blue-11;
}

.token.url {
  @apply text-contrast;
}

.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.namespace {
  opacity: 0.7;
}
